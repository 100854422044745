import store from "@/store";

export function triggerAlert(from) {
  const sourceRoutes = [
    {
      name: ["edit-hospital-service", "add-hospital-service"],
      module: "services",
    },
    {
      name: [
        "edit-consultation-form-continuation",
        "citizens-consultations-view",
      ],
      module: "consultation_form",
    },
  ];

  const alertRoutes = [...sourceRoutes.flatMap((route) => [...route.name])];

  if (alertRoutes.includes(from.name)) {
    const whichModule = sourceRoutes.find((item) =>
      item.name.includes(from.name)
    );
    const alertProperties = store.state[whichModule.module].alertProperties;

    if (alertProperties.type === "success") {
      store.commit("alerts/SET_SHOW_ALERT", alertProperties.message);
    } else if (alertProperties.type === "error") {
      store.commit("alerts/SET_SHOW_ERROR", alertProperties.message);
    }
  }
}

export function resetAlertValues(from) {
  const resetModule = [
    {
      name: ["hospital-services"],
      module: "services",
    },
    {
      name: ["citizens-consultations", "citizens-consultations-view"],
      module: "consultation_form",
    },
  ];

  const resetAlertRoutes = [...resetModule.flatMap((route) => [...route.name])];

  if (resetAlertRoutes.includes(from.name)) {
    const whichModule = resetModule.find((item) =>
      item.name.includes(from.name)
    );
    const module = whichModule.module;

    store.commit(`${module}/SET_ALERT_PROPERTIES`, {
      type: null,
      message: "",
    });
  }
}
