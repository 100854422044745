import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const message = {
  namespaced: true,
  state: () => ({
    message: "",
    opened: false,
    onClose: null,
  }),
  getters: {},
  mutations: {
    SET_TRIGGER_MESSAGE(state, { message, isOpen, onClose }) {
      state.message = message;
      state.opened = isOpen;
      state.onClose = onClose;
    },
  },
  actions: {
    triggerMessage: function ({ commit }, properties) {
      commit("SET_TRIGGER_MESSAGE", {
        ...properties,
        isOpen: true,
      });
    },
    resetMessage: function ({ commit }) {
      commit("SET_TRIGGER_MESSAGE", {
        message: "",
        isOpen: false,
        onClose: null,
      });
    },
  },
};
