import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const management = {
  namespaced: true,
  state: () => ({
    userRoles: [],
  }),
  getters: {},
  mutations: {
    SET_USER_ROLES(state, data) {
      state.userRoles = data;
    },
  },
  actions: {
    fetchUserRoles({ commit }) {
      const url = `enums/user-roles`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_USER_ROLES", response.data);
        })
        .catch((error) => {
          console.error("Error Fetching User Roles Enum: ", error);
        });
    },
  },
};
