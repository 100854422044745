import store from "@/store";
import router from "@/router";

export const auth = {
  user: function () {
    let user = store.getters["authentication/getCurrentUser"];
    return user;
  },
  token: function () {
    let user = store.getters["authentication/getCurrentUser"];
    return user && user.token ? user.token : null;
  },
  role: function () {
    let user = store.getters["authentication/getCurrentUser"];
    return user && user.user.role ? user.user.role : null;
  },
  logout: function () {
    store.dispatch("message/triggerMessage", {
      message: "Your session has expired. Redirecting to log in page.",
      onClose: () => {
        store.dispatch("authentication/logoutAndClearToken");
        store.dispatch("message/resetMessage");
        router.push({ name: "login" });
      },
    });
  },
};
