import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const features = {
  namespaced: true,
  state: () => ({
    features: null,
    tableLoader: {
      generalServices: false,
      features: false,
      both: false,
    },
  }),
  getters: {},
  mutations: {
    SET_FEATURES: (state, data) => {
      state.features = data;
    },
    SET_TABLE_LOADER: (state, { isTrigger, type }) =>
      (state.tableLoader[type] = isTrigger),
  },
  actions: {
    fetchFeatures({ commit }, loaderType) {
      commit("SET_TABLE_LOADER", { isTrigger: true, type: loaderType });
      const url = `features`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_FEATURES", response.data);
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", {
            message: error.response.data.message,
            route: "features",
          });
          console.log("Error fetching features: " + error);
        })
        .finally(() => {
          commit("SET_TABLE_LOADER", { isTrigger: false, type: loaderType });
        });
    },
    toggleFeature({ dispatch }, { data, loaderType }) {
      const url = `features`;
      return this.$axios
        .patch(url, data)
        .then((response) => {
          this.$store.commit("alerts/SET_SHOW_ALERT", {
            message: response.data.message,
            route: "features",
          });
          dispatch("fetchFeatures", loaderType);
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", {
            message: error.response.data.message,
            route: "features",
          });
          console.error("Error updating features: " + error);
        });
    },
  },
};
