import Vuex from "vuex";
import Vue from "vue";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const services = {
  namespaced: true,
  state: () => ({
    hospitalServices: null,
    hospitalService: null,
    alertProperties: {
      type: null,
      message: "",
    },
  }),
  getters: {
    getHospitalService: (state) => state.hospitalService,
  },
  mutations: {
    SET_HOSPITAL_SERVICES(state, services) {
      state.hospitalServices = services;
    },
    SET_HOSPITAL_SERVICE(state, service) {
      state.hospitalService = service;
    },
    SET_ALERT_PROPERTIES(state, properties) {
      state.alertProperties.type = properties.type;
      state.alertProperties.message = properties.message;
    },
  },
  actions: {
    fetchServicesById({ commit }, { id, queryParams = {} }) {
      this.$store.dispatch("loading/triggerTableLoader");
      const url = `citizens/${id}/hospital-services`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_HOSPITAL_SERVICES", response.data.hospitalServices);
        })
        .catch((error) => {
          console.error("Error requesting services: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerTableLoader");
        });
    },
    fetchHospitalServiceById({ commit }, { id, hospital_service_id }) {
      const url = `citizens/${id}/hospital-services/${hospital_service_id}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_HOSPITAL_SERVICE", response.data.data);
        })
        .catch((error) => {
          console.error("Error requesting service: ", error);
        });
    },
    addHospitalService({ dispatch, commit }, { id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/${id}/hospital-services`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          dispatch("fetchServicesById", { id });
          commit("SET_ALERT_PROPERTIES", {
            type: "success",
            message: response.data.message,
          });
          return response;
        })
        .catch((error) => {
          commit("SET_ALERT_PROPERTIES", {
            type: "error",
            message: error.response.data.message,
          });
          console.error("Error adding services: ", error);
          throw error;
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    updateHospitalService({ dispatch, commit }, { id, hospital_service_id, data }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "submit");
      const url = `citizens/${id}/hospital-services/${hospital_service_id}`;
      return this.$axios
        .put(url, data)
        .then((response) => {
          dispatch("fetchServicesById", { id });
          dispatch("fetchHospitalServiceById", {
            id: id,
            hospital_service_id: hospital_service_id,
          });
          commit("SET_ALERT_PROPERTIES", {
            type: "success",
            message: response.data.message,
          });
        })
        .catch((error) => {
          commit("SET_ALERT_PROPERTIES", {
            type: "error",
            message: error.response.data.message,
          });
          console.error("Error Updating Hospital Service: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "submit");
        });
    },
    deleteHospitalService({ dispatch }, { id, hospital_service_id }) {
      this.$store.dispatch("loading/triggerFunctionLoader", "delete");
      const url = `citizens/${id}/hospital-services/${hospital_service_id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          dispatch("fetchServicesById", { id });
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          this.$store.commit(
            "alerts/SET_SHOW_ERROR",
            error.response.data.message
          );
          console.error("Error Deleting Service: ", error);
        })
        .finally(() => {
          this.$store.dispatch("loading/detriggerFunctionLoader", "delete");
        });
    },
  },
};